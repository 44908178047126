.logo img {
  height: 70px;
  width: auto;
  transition: all 0.3s;
  overflow: hidden;
}

.logo img:hover {
  transform: scale(1.1);
}

.navLinks {
  list-style: none;
}

.navLinks li {
  display: inline-block;
  padding: 0 16px;
  border-right: 1px solid var(--primary-color);
  font-size: 0.6rem;
  font-weight: bold;
}

.navLinks li:hover {
  transform: scale(1.1);
}

.navLinks a {
  color: var(--primary-color);
  text-decoration: none;
}

.navLinks li a:hover {
  color: var(--text-color);
}

.header_desktop {
  display: none;
}

.tittle {
  font-family: URWFormHeavy;
}

.subTittle {
  font-family: URWFormDemi;
}

.button {
  font-weight: 600;
  background-color: var(--secondary-color);
}

.header_left {
  background-image: url(../../../assets/images/bg-header.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.container_image {
  height: 100%;
}

.container_image img {
  display: block;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .container_image {
    margin-top: -60px;
  }
}
@media screen and (min-width: 600px) {
  .headers {
    height: calc(100vh - 190px);
  }
  .header {
    height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
    overflow: hidden;
  }
  .container_image {
    position: relative; /* establecemos la posición del div como relativa */
  }
  .container_image img {
    position: absolute;
    bottom: 0;
    left: -20%;
    height: 100%; /* mantiene la proporción de aspecto de la imagen */
    width: auto;
  }
  .theMore {
    position: absolute;
    top: 0;
    right: -5%;
  }
}

@media screen and (min-width: 1440px) {
  .container_image img {
    height: 100%;
    transform: translateY(80px);
  }
}

@media screen and (min-width: 992px) {
  .header_movil {
    display: none;
  }

  .header_desktop {
    display: block;
  }
}
