.navLinks {
  list-style: none;
}

.navLinks li {
  display: inline-block;
  padding: 0 15px;
  font-size: 0.7rem;
  transition: all 0.5s ease;
}

.navLinks li:hover {
  transform: scale(1.1);
}

.navLinks a {
  color: var(--text-color);
  text-decoration: none;
  font-family: "URWFormBold";
}

.navLinks li a:hover {
  color: var(--text-color);
}


@media screen and (max-width: 600px) {
  .navLinks li {
    text-align: center !important;
    margin: 0 auto !important;
    padding: 5px 0;
    display: flex !important;
    flex-direction: column !important;
    justify-content: center !important;
    /* align-items: flex-end !important; */
    /* min-height: 90px; */
  }
}