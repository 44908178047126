.footerNewSeccion {
  overflow-x: hidden !important;
  background: rgba(67, 66, 67, 0.08);
}

.logoFooter {
  text-align: center !important;
}

.logoFooter img {
  width: 90% !important;
  height: auto !important;
  max-width: 304px;
  max-height: 87px;
}

.contenedorMenu {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.lisSocial {
  text-align: center !important;
  margin: 0 auto !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: flex-end !important;
  min-height: 90px;
}

.lisSocial i {
  color: var(--secondary-color) !important;
  font-size: 2rem;
  padding: 0 10px !important;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.footerContacto p {
  font-size: 0.8rem;
  font-family: "URWFormBold";
  color: var(--text-color);
}

.footerContacto h2,
.footerContacto h3 {
  color: var(--primary-color);
  font-size: 1rem;
}

@media screen and (max-width: 600px) {
  .logoFooter img {
    width: 50% !important;
    height: auto;
  }

    .footerContacto,
    .footerContacto h2,
    .footerContacto h3 .footerContacto p {
      text-align: center !important;
    }
}