.container {
  background-image: url(../../../assets/images/customers.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 5rem;
  background-attachment: fixed;
}
.container_image {
  text-align: center;
}
.container_image img {
  max-width: 200px;
  height: 200px;
}
.hr {
  width: 20%;
  height: 10px;
  border: 2px solid var(--secondary-color);
  background-color: var(--secondary-color);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
@media screen and (max-width: 600px) {
  .container {
    padding: 1rem;
  }
  .hr{
    margin: 0 auto;
  } 
}