@font-face {
  font-family: "URWFormRegular";
  font-style: normal;
  font-weight: normal;
  src: local("URWFormRegular"),
    url("./assets/fonts/URWForm-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "URWFormItalic";
  font-style: italic;
  font-weight: normal;
  src: local("URWFormItalic"),
    url("./assets/fonts/URWForm-Italic.ttf") format("truetype");
}

@font-face {
  font-family: "URWFormBold";
  font-style: normal;
  font-weight: bold;
  src: local("URWFormBold"),
    url("./assets/fonts/URWForm-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "URWFormHeavy";
  font-style: normal;
  font-weight: bold;
  src: local("URWFormHeavy"),
    url("./assets/fonts/URWForm-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: "URWFormDemi";
  font-style: normal;
  font-weight: bold;
  src: local("URWFormDemi"),
    url("./assets/fonts/URWForm-Demi.ttf") format("truetype");
}

@font-face {
  font-family: "URWFormExtraBold";
  font-style: normal;
  font-weight: bold;
  src: local("URWFormExtraBold"),
    url("./assets/fonts/URWForm-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "URWFormExtraBoldItalic";
  font-style: italic;
  font-weight: bold;
  src: local("URWFormExtraBoldItalic"),
    url("./assets/fonts/URWForm-BoldItalic.ttf") format("truetype");
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

html {
  font-size: 20px;
  /* 100% = 16px */
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-bg);
  background-repeat: no-repeat !important;
  background-position: top center !important;
  background-size: cover !important;
  background-attachment: fixed !important;
  background-repeat: repeat;
  background-size: 100%;
  font-family: "URWFormRegular", sans-serif;
  font-weight: normal;
  line-height: 1.7;
  overflow-x: hidden;
}

:root {
  --color-bg: #ffffff;
  --primary-color: #1980b4;
  --secondary-color: #f6a431;
  --text-color-secondary: #f6a431;
  --text-color: #434243;
  --text-secondary: #fff;
  --color-white: #fff;
  --color-gray: #494e4f;
  --color-gray-dark: #635858;
  --transition: all 400ms ease;
  --font-family: URWFormRegular;
  --container-width-lg: 90%;
  --container-width-md: 94%;
  --container-width-sm: 96%;
  --border-radius: 40px;
}

.my_container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

p,
h3 {
  line-height: 100%;
  word-spacing: 1px;
}

.guias {
  border: 1px solid #000;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  background: var(--text-color);
}

.btn-Gen,
.btn-secondary {
  background: var(--secondary-color);
  color: var(--text-secondary);
  border: 1px solid var(--secondary-color);
  font-size: 26px;
  text-decoration: underline;
  font-family: "URWFormRegular";
  font-weight: 600;
  padding: 12px 50px;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.btn-Gen:hover,
.btn-Gen:active {
  background: transparent;
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}

.btn-secondary:hover,
.btn-secondary:active {
  background: transparent;
  color: var(--text-secondary);
  border: 1px solid var(--text-secondary);
}

/* Icon Back */
.hvr-icon-back {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
}

.hvr-icon-back .hvr-icon {
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.hvr-icon-back:hover .hvr-icon,
.hvr-icon-back:focus .hvr-icon,
.hvr-icon-back:active .hvr-icon {
  -webkit-transform: translateX(-4px);
  transform: translateX(-4px);
}

.btnEmail,
.btnEmailContact,
.btnError {
  background-color: var(--secondary-color);
  padding: 0 30px;
  margin: 10px 0;
  border-radius: 15px;
  color: var(--color-white);
  border: 1px solid var(--secondary-color);
  transition: all 0.5s ease;
}

.btnEmail:hover,
.btnEmailContact:hover,
.btnError:hover {
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
  background-color: transparent;
}

.btnEmailContact {
  font-size: 1.2rem;
  padding: 5px 45px;
  margin: o auto;
}

.guias{
  border: #000 solid 1px;
}

.ocultarse {
  display: none;
}

.visiblesm {
  display: none !important;
}

.bm-burger-button {
  display: none;
}

.containerCutomer{
  background: var(--color-white);
  color: var(--primary-color);
  padding: 15px 20px;
  border: #f6a431 solid 1px;
  font-size: 0.95rem;
  font-weight: bold;
  margin-top: 20px;
}
/* ===================== MEDIA QUERIES (DISPOSITIVOS MEDIOS) ===================== */

@media screen and (max-width: 1024px) {
  .my_container {
    width: var(--container-width-md);
  }
}

/* ===================== MEDIA QUERIES (DISPOSITIVOS PEQUEÑOS) ===================== */

@media screen and (max-width: 600px) {
  .my_container {
    width: var(--container-width-sm);
  }

  .btn-Gen, .btn-secondary {
    font-size: 18px;
    padding: 12px 35px;
  }
}

@media only screen and (max-width: 992px) {
  .visiblemd {
    display: none !important;
  }

  .visiblesm {
    display: flex !important;
  }

  .bm-burger-button {
    display: flex;
  }
}



/* OTROS */

/* Bounce In */
.hvr-bounce-in {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}

.hvr-bounce-in:hover,
.hvr-bounce-in:focus,
.hvr-bounce-in:active {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
  transition-timing-function: cubic-bezier(0.47, 2.02, 0.31, -0.36);
}

/* Grow */
.hvr-grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
}

.hvr-grow:hover,
.hvr-grow:focus,
.hvr-grow:active {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

/* Skew Backward */
.hvr-skew-backward {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
}

.hvr-skew-backward:hover,
.hvr-skew-backward:focus,
.hvr-skew-backward:active {
  -webkit-transform: skew(10deg);
  transform: skew(10deg);
}

.button_yellow {
  font-family: URWFormExtraBold;
  background-color: var(--secondary-color);
  padding: 10px 100px;
  font-size: 1.3rem;
  color: var(--color-white);
  cursor: pointer;
  border-radius: var(--border-radius);
  text-decoration: underline;
  border: 1px solid var(--secondary-color);
}

.button_yellow:hover {
  background-color: transparent;
  border: 1px solid white;
}

.button_yellow_two {
  font-family: URWFormExtraBold;
  background-color: var(--secondary-color);
  padding: 10px 60px;
  font-size: 1.3rem;
  color: var(--color-white);
  cursor: pointer;
  border-radius: var(--border-radius);
  text-decoration: underline;
  border: 1px solid var(--secondary-color);
  margin-bottom: 1rem;
}

.button_yellow_two:hover {
  background-color: transparent;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.active {
  color: var(--text-color) !important;
}

.activeMovil {
  color: var(--primary-color) !important;
}

.dhidden {
  display: none;
}

.mhidden {
  display: block;
}

@media (min-width: 1024px) {
  .button_yellow_two {
    font-size: 1rem;
  }
}

@media (min-width: 1920px) {
  .button_yellow_two {
    font-size: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .button_yellow {
    padding: 10px 80px;
    font-size: 1rem;
  }

  .button_yellow_two {
    padding: 10px 40px;
    font-size: 1rem;
  }

  .dhidden {
    display: block;
  }

  .mhidden {
    display: none;
  }
}