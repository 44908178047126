.title {
  margin: 0;
  width: 60%;
  text-align: center;
  font-family: URWFormRegular;
  color: var(--text-color);
}

.lineLeft {
  width: 20%;
  height: 4px;
  background-color: var(--primary-color);
  margin: auto 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.lineRight {
  width: 20%;
  height: 4px;
  background-color: var(--primary-color);
  margin: auto 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.container_image {
  text-align: center;
}

.container_image img {
  max-width: 90%;
  /* La imagen no será más ancha que el contenedor */
}

@media screen and (max-width: 600px) {
  .container_image img {
    width: 60%;
  }
}

@media screen and (min-width: 992px) {
  .title {
    width: 40%;
  }

  .lineLeft {
    width: 30%;
  }

  .lineRight {
    width: 30%;
  }
}