.container {
  background-image: url(../../../assets/images/signitiva_desk.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  padding: 5rem;
}

.hr {
  max-width: 20%;
  min-width: 16%;
  height: 8px;
  border-top: 2px solid var(--secondary-color);
  background-color: var(--secondary-color);
  border-radius: 20px;
  margin: 0 auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.subTitle{
  margin: 0 3rem;
}
.learnmore{
  color: var(--primary-color);
  text-decoration: underline;
}

.container_image img {
  width: 121px;
  height: 121px;
  display: none;
}

@media screen and (max-width: 600px) {
  .container_image img {
    display: block;
  }
  .container {
    background-image: url(../../../assets/images/signitiva_movil.png);
    padding: 2.5rem 1.5rem 5.5rem 1.5rem;
  }
  .subTitle{
    margin: 0;
  }
}