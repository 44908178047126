.seccionNewC {
    padding: 70px 50px 0px 50px !important;
}

.seccionNewCBorder {
    border: 2px solid #F19F31;
    background: rgba(246, 164, 49, 0.07);
}

.seccionNewCBorder p {
    font-family: URWFormItalic;
    font-size: 1.2em;
    font-weight: bold !important;
    line-height: 1.1;
    margin-bottom: 20px;
    margin-top: 20px;
}

.seccionNewCBorder {
    text-align: center;
}

.seccionNewCBorder img {
    width: 100%;
    max-width: 200px;
    max-height: auto !important;
    height: auto !important;
    padding: 25px;
}

.btn-contact {
    background: var(--color-white);
    border: #F19F31 2px solid;
    border-radius: 25px;
    font-size: 1.2em;
    color: #F19F31;
    font-family: URWFormBold;
    text-decoration: underline;
    padding: 10px 50px;
    transition: all 0.5s ease;
    cursor: pointer;
}

.btn-contact:hover,
.btn-contact:active {
    background: #F19F31;
    border:#F19F31 2px solid;
    color: var(--color-white);
}


@media screen and (max-width: 600px) {
    .seccionNewCBorder p{
        font-size: 0.9em;
    }
}

