.container_tittle {
  background: var(--secondary-color);
  border-radius: 10px;
}

.tittle {
  color: var(--text-secondary);
  text-align: center;
  font-family: "URWFormItalic";
  font-weight: 700;
  font-size: 36px;
}

.container_text {
  background-color: rgb(246 164 49 / 10%);
}

.button {
  font-family: URWFormExtraBold;
  background-color: transparent;
  border: 2px solid var(--color-white);
  height: 70px;
  padding: 0 1rem;
}

.list {
  line-height: normal;
}

.list ul {
  line-height: 1 !important;

}

.list ul li {
  font-weight: 700;
  padding: 25px 0 !important;
}

.list ul li::before {
  content: "\2022";
  color: var(--secondary-color);
  font-weight: bold;
  display: inline-block;
  width: 1em;
}

.container_text {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  font-weight: bold;
}

.why {
  font-family: "URWFormItalic";
}

.visible {
  display: none;
}


@media screen and (max-width: 600px) {
  .visible {
    display: block;
  }

  .list {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .button {
    padding: 0 1rem;
    height: 60px;
  }

  .container_tittle {
    border-radius: 0;
  }

  .container_text {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .tittle {
    text-align: left;
    font-size: 28px;
    line-height: 1.3;
    padding: 0 10px;
  }

  .why {
    text-align: left;
    margin: 0;
  }

  .list ul {
    padding: 0 15px !important;
  }
}