.headerScroll {
    position: fixed !important;
    transition-property: all !important;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
    transition-duration: 500ms !important;
    background-color: transparent !important;
    width: 100% !important;
    overflow-x: hidden !important;
}

.headerScroll-show {
    top: 0px !important;
    background: rgba(255, 255, 255, 0.5) !important;
}

.headerScroll-hide {
    top: -6rem !important;
}

/*Menu Escritorio*/
.header_desktop {
    display: flex;
    background: transparent !important;
    position: relative;
    z-index: 10000;
}