.container {
  background-image: url(../../../assets/images/bg_ofers.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.hr {
  max-width: 18%;
  min-width: 15%;
  height: 8px;
  border-top: 2px solid var(--secondary-color);
  background-color: var(--secondary-color);
  border-radius: 20px;
  margin: 0 auto;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.tittle {
  font-family: "URWFormExtraBoldItalic";
}

.subTittle {
  font-family: "URWFormExtraBoldItalic";
}

.subTittleT {
  font-family: "URWFormRegular";
}

@media screen and (max-width: 600px) {
  .hr {
    max-width: 50%;
      min-width: 40%;
    height: 5px;
  }
}